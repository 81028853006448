.parent-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.child-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.child-container .css-wb57ya-MuiFormControl-root-MuiTextField-root {
  margin-bottom: 0px !important;
}
.date-picker-section .react-calendar-datetime-picker .header {
  background-color: var(--color-secondary-dark) !important;
}
.react-calendar-datetime-picker .daysList .is-disabled {
  background-color: #fff !important;
  color: #fff !important;
}

.date-picker-section .react-calendar-datetime-picker .daysList .is-today {
  box-shadow: inset 0 0 0 2px var(--color-secondary-dark) !important;
}
.date-picker-section
  .react-calendar-datetime-picker
  .daysList
  .is-selected-day-range {
  background-color: var(--color-secondary-dark) !important;
  color: black !important;
}
.date-picker-section
  .react-calendar-datetime-picker
  .daysList
  .is-selected-day-to {
  background-color: var(--color-secondary-dark) !important;
}
.date-picker-section
  .react-calendar-datetime-picker
  .monthList_month.is-selected-month {
  color: var(--color-secondary-dark) !important;
}
.date-picker-section .react-calendar-datetime-picker .is-selectedYearRef {
  color: var(--color-secondary-dark) !important;
}
.date-picker-section
  .react-calendar-datetime-picker
  .daysList
  .is-selected-day-from {
  background-color: var(--color-secondary-dark) !important;
}
.date-picker-section .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  background-color: #fff !important;
}
.check-date-div .css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 0px !important;
  margin: 0px !important;
}
.container-first > p {
  /* font-size: var(--fs-subheading); */
  font-size: 2rem;

  color: var(--color-blue2);
  margin: 0;
  font-family: var(--family-bold);
  margin-bottom: 32px;
}
.calender-section > button {
  padding: 5px 8px;
}
/* .react-calendar-datetime-picker .calender-modal {
  background-color: red !important;
} */
.grand-container {
  display: flex;
   gap: 21px;
}
.grand-container .css-3g2bwf-MuiSlider-valueLabel {
  font-size: 0rem !important;
  background-color: transparent !important;
  color: transparent !important;
}
