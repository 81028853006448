.spcace23 .css-1qa2lrh-MuiSvgIcon-root {
  margin-top: -20px;
}
.disabled {
  pointer-events: none;
  opacity: 0.6;
}
.paper-chart {
  max-width: 99%;
  /* width: 100%; */
  height: 15rem;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.paper-chart-map {
  position: relative;
  width: 100%;
  min-height: 31.39rem;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.filter-div {
  border-radius: 20px;
  padding: 0.5rem;
  background-color: var(--color-white);
}
.color-style {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  background-color: var(--color-primary) !important
  ;
}
.sort-div {
  margin-right: 11rem;
  padding: 0.15rem;
  height: 2.5rem;
  border-radius: 20px;
  background-color: var(--color-white);
}

.sort-div-section {
  min-width: 40%;
}

.sort-div p {
  margin: 0;
  color: var(--color-secondary-dark);
  font-family: var(--family-head);
  font-style: var(--family-style);
  font-size: var(--fs-lg);
  font-weight: 500;
}
input {
  outline: none;
}
.search-div {
  border-radius: 20px;
  padding: 0.5rem;
}

.search-input {
  border: none;
  color: var(--color-miscellaneous-1);
  font-size: var(--fs-mid);
}

.skeleton-div > span {
  height: 15rem;
  margin-bottom: 1rem;
  transform: none;
  -webkit-transform: none;
}

.searchShipmentDiv {
  position: relative;
  /* width: 40%;
  min-width: 25rem; */
}

.label_input {
  background-color: #fff;
}

.nav-active-div {
  background-color: var(--color-primary) !important;

  font-family: var(--family-head);
  font-style: var(--family-style);
  font-weight: 500;
  font-size: var(--fs-lg);
  border-radius: 22.2px !important;
}

.backgroundColor {
  background: var(--color-primary) !important;
  color: var(--color-white) !important;
}

.nav-div {
  background-color: var(--color-white) !important;
  color: var(--color-secondary-dark);
  font-family: var(--family-head);
  font-style: var(--family-style);
  font-weight: 500;
  font-size: var(--fs-lg);
  border-radius: 22.2px !important;
}

/* ---Basic details--- */
.basic-head {
  font-family: var(--family-title);
  font-style: var(--family-style);
  font-weight: 600;
  font-size: var(--fs-subhead);
  color: var(--color-black);
}

.width-84 {
  width: 80%;
}

.width-74 {
  width: 67%;
}

.width-85 {
  width: 81%;
}

.basic-specific {
  font-family: var(--family-label);
  font-style: var(--family-style);
  font-weight: 600;
  /* font-size: var(--fs-lg); */
  font-size: var(--fs-subtitle);
  color: var(--color-secondary-light);
}

.title-text {
  color: var(--color-primary);
  padding: 0.5rem;
  font-family: var(--family-label);
  font-style: var(--family-style);
  font-weight: 700;
  font-size: var(--fs-head);
}

.subtitle-text {
  color: var(--color-primary);
  padding: 0.5rem;
  font-family: var(--family-label);
  font-style: var(--family-style);
  font-weight: 400;
  font-size: var(--fs-subtitle);
}

.mapchart .gmnoprint,
.mapchart .gm-control-active.gm-fullscreen-control {
  display: none;
}

.text-head {
  font-family: var(--family-label);
  font-style: var(--family-style);
  font-weight: 400;
  font-size: var(--fs-mid);
  color: var(--color-dkblack);
  line-height: 21px;
}

.text-sub {
  font-family: var(--family-label);
  font-style: var(--family-style);
  font-weight: 400;
  font-size: var(--fs-subtitle);
  color: var(--color-miscellaneous-2) !important;
  
}

/* --Routing-- */
.major-milestone {
  float: left;
  position: absolute;
  top: 5rem;
  left: 2.8rem;
  width: 24rem;
}

.cw1-milestone {
  top: 13%;
  left: 35rem;

  width: 24rem;
}

.milestone {
  width: 90%;
  position: absolute !important;
  top: 30rem;
  left: 2.6rem;
}

.map-div {
  background-color: var(--color-miscellaneous-6);
  border-radius: 8px;
}

.map-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  color: #286085;
}

.map-image-div {
  width: 99%;
}

.map-text-head {
  font-family: var(--family-label);
  font-style: var(--family-title);
  font-weight: 500;
  font-size: var(--fs-mid);

  color: var(--color-primary);
}

.map-text-section {
  margin-top: -12px;
}

.pack-data {
  font-family: var(--family-label);
  font-style: var(--family-title);
  font-weight: 400;
  font-size: var(--fs-small);
  color: var(--color-secondary-dark);
}

.pack-data-time {
  font-family: var(--family-label);
  font-style: var(--family-title);
  font-size: var(--fs-small);
  font-weight: 400;
  color: var(--color-ternary);
}

.pack-data-del {
  font-family: var(--family-label);
  font-style: var(--family-title);
  font-size: var(--fs-small);
  font-weight: 400;
  color: var(--color-miscellaneous-7);
}

.cw1-card {
  border-top: 2px solid var(--color-primary-light);
}

.cw1-text {
  border-right: 2px solid var(--color-primary-light);
}

.mapping-description {
  min-width: 7rem;
  max-width: 7rem;
  /* margin-right: 5.5rem; */
  margin-right: 3.2rem;
}
.css-ay4bup-MuiGrid-root
/* --Cargo Details-- */
.img-ship-cargo {
  height: 6rem;
  width: 6rem;
}

.circleDiv {
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  background-color: var(--color-primary);
}

.lineDiv {
  height: 0.2rem;
  width: 8rem;
  background-color: var(--color-primary);
}

.typo-font {
  font-size: var(--fs-new);
}

.cw1-data {
  width: 7.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* --Documentation-- */
.form-check {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 18rem;
}
.btn-doc1 {
  background-color: var(--color-miscellaneous-5);
  border-radius: 35px;
  font-family: var(--family-label);
  font-style: var(--family-style);
  font-weight: 600;
  font-size: var(--fs-mid);
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
}

.documentation {
  border-spacing: 0 1rem;
  border-collapse: separate;
  background-color: var(--color-miscellaneous-6);
}

.table th {
  border: none;
}

.documentation-row {
  border-bottom: 1px solid var(--color-primary-light);
}

.documentation-info td {
  padding: 0.75rem;
}

.pdf-icon {
  height: 2.5rem !important;
  width: 3rem !important;
}

.index-card {
  height: 67vh;
  overflow-y: auto;
}

div.layout-details-div.mx-3
  div.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4
  div.MuiAccordionSummary-expandIconWrapper.Mui-expanded.css-yw020d-MuiAccordionSummary-expandIconWrapper {
  display: none;
}
.form-check .form-check-input {
  margin-left: 0;
}
.sip-card {
  display: none;
}
/* -----Media query---------- */
@media only screen and (min-width: 0px) and (max-width: 760px) {
  .sip-card ul li {
    font-size: 14px !important;
  }

  .sip-card ul li:nth-child(2) {
    font-weight: 500 !important;
  }

  .css-6h2bki-MuiButtonBase-root-MuiButton-root {
    min-width: auto !important;
  }

  .tableBody-div {
    display: none;
  }

  .sip-card {
    display: block !important;
  }

  .card {
    border: 0px solid rgba(0, 0, 0, 0.125) !important;
    margin-bottom: 10px;
    border-radius: 32px;
  }
}
/*-------------- Shipment List view css ------------------*/
/* .word-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 2rem;
  display: inline-block;
} */
.tableWrap {
  height: 75vh;
  overflow: auto;
  overflow-x: scroll;
  width: 100%;
}

.table {
  overflow-x: auto;
}

thead {
  z-index: 1000;
  background-color: var(--color-miscellaneous-1);
  position: sticky;
  top: 0;
}

thead tr th {
  position: sticky;
  top: 0;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

table {
  border-collapse: collapse;
}

th {
  padding: 16px;
  padding-left: 15px;
  border-left: 1px dotted rgba(200, 209, 224, 0.6);
  background-color: var(--color-miscellaneous-6);
  text-align: left;
  box-shadow: 0px 0px 0 2px #e8e8e8;
  min-width: 170px;
}

table {
  width: 100%;
  font-family: sans-serif;
}

table td {
  padding: 16px;
  min-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

tbody tr {
  border-bottom: 2px solid #e8e8e8;
}

thead {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}

tbody tr:hover {
  background: #e6f7ff;
}

.font-table {
  font-size: var(--fs-mid);
}
/* -------List view details--------- */

.tabrows {
  padding-top: 1rem;
  border-bottom: 1px solid var(--color-primary-light);
  /* padding-bottom: 1rem; */
}

.tab-text {
  font-size: var(--fs-title);
  /* font-weight: 700; */
  cursor: pointer;
  padding: 0;
  margin: 0 1.4rem 0 0;
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--color-miscellaneous-2);
  font-family: var(--family-label);
  font-style: var(--family-style);
  font-weight: 400;
  font-size: var(--fs-subtitle);
  line-height: 30px;
}
.tab-text-data {
  font-size: var(--fs-sublabel);
  font-weight: 700;
  padding: 0;
  margin: 0 1.4rem 0 0;
  color: var(--color-secondary-dark);
  padding-bottom: 0.5rem;
  font-family: var(--family-title);
  font-style: var(--family-style);
  line-height: 38px;
}

.tab-text-active {
  border-bottom: 5px solid var(--color-secondary-dark);
  font-weight: 600;
  /* font-size: var(--fs-head); */
  line-height: 30px;
  /* identical to box height */

  /* Text Primary */

  color: #151619;
}

.doc-upload-icon {
  position: fixed;
  bottom: 2rem;
  right: 7rem;
  height: 5rem;
}

.label_input {
  background-color: #fff;
}

/* --------------ModalShipment------------------- */
.modal-sort-color {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.modal-card {
  border: 1px solid var(--color-primary);
  border-radius: 5rem;
  cursor: pointer;
  font-size: 0.7rem !important;
}
.radius-sort {
  border-radius: 5rem;
  border: 1px solid var(--color-primary);
}

.border-sort {
  border-top: 1px solid var(--color-primary-light);
}

.mainDivRouting {
  width: 40%;
}

.bottomDivRouting {
  width: 60%;
}

.mapingDiv
  div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.Mui-expanded.MuiAccordion-gutters {
  width: 100%;
  overflow-y: auto;
}

.invisible {
  display: none;
}
/* ---------TableColumnModalCss--------- */
.Dialog-section div:nth-child(3) > div {
  padding: 6px 0px;
}
.list-section li {
  list-style: none;
}
.highlight {
  background-color: var(--color-secondary);
  color: var(--color-white);
}
.no-highlight {
  cursor: pointer;
}
.color-back {
  color: var(--color-secondary-dark) !important;
  cursor: pointer;
  font-family: var(--family-label);
}
.font-shipment {
  font-family: var(--family-label) !important;
}
.font-change {
  font-family: var(--family-head) !important;
}
.head-listview {
  font-size: var(--fs-mid);
  font-weight: 500;
  line-height: 30px;
}
.headersearch.searchShipmentDiv input.form-control {
  padding: 0.65rem 2rem 0.65rem 2.5rem;
  font-size: 1rem;
  border-radius: 8px !important;
  border: none;
}
.tooltip-container {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 1000;
}
.sort-container {
  position: relative;
  text-align: center;
  font-size: 18px;
  border: 1px solid var(--color-secondary-light);
  cursor: pointer;
  border-radius: 5px;
  font-family: var(--family-label);
  padding-bottom: 20%;
  padding-top: 20%;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out; /* Add background-color for smoother transition */
}

.sort-container:hover {
  transform: scale(1.05);
  background-color: rgba(255, 255, 255, 0.9); /* Optional: change background color on hover */
}

.sort-label {
  font-size: 18px;
  font-weight: bold;
  transition: color 0.3s ease-in-out; 
}

.sort-subtext {
  font-size: 12px;
  color: var(--color-tertiary-font);
  transition: color 0.3s ease-in-out;
}

.sort-options {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}

.sort-container:hover .sort-options {
  opacity: 1;
}

.sort-options button {
  flex: 1;
  color: var(--color-secondary-dark);
  transition: background-color 0.3s ease-in-out;
}

.sort-options button:hover {
  background-color: var(--color-primary-light);
}

/* --Media-query-- */
@media (max-width: 600px) {
  .sort-container {
    font-size: 16px;
    padding: 12px;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1170px) {
  .btn-documents {
    margin-right: 1rem;
  }
}
/* --Media-query-- */
@media screen and (max-width: 479px) {
  .filter-div img {
    height: 1rem;
  }

  .sort-div img {
    font-size: 12px;
    height: 1rem;
  }
}

@media only screen and (max-width: 760px) {
  .index-card {
    overflow: unset;
  }

  .outlet-shipment-details {
    display: none;
  }

  .shipment-card-div {
    display: none;
  }
  /* .shipment-card-div-list {
  } */
  .img-ship-cargo {
    height: 3rem;
    width: 3rem;
  }

  .sort-div-section {
    padding-bottom: 0.5rem;
  }
}

@media only screen and (max-width: 760px) {
  .mapMain {
    flex-direction: column;
  }

  .mainDivRouting {
    width: 100%;
  }

  /* .locate-div .scdc {
    width: 93% !important;
  } */
  .bottomDivRouting {
    width: 100%;
  }
}
/* --------------------------- */
.ellipsis {
  position: relative !important;
}
.ellipsis::before {
  content: "&nbsp" !important;
  visibility: hidden !important;
}
.ellipsis > span {
  position: absolute !important;
  left: 13px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  width: 100px;
}
.wordWrap-doc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.invoice {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #286085;
}
.small-btn-mat {
  min-width: 33px !important;
  padding: 1px 15px !important;
  border-radius: 12px !important;
  font-size: 0.7125rem !important;
}
.doc-font-header {
  color: #212529;
  font-weight: 700;
}
/* -Analyitcs--- */
.autocomplete-data .css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0 !important;
}
.autocomplete-data .css-16gcrfd-MuiFormLabel-root-MuiInputLabel-root {
  /* bottom: 14px; */
  top: -6px !important;
}

/* .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthMd.MuiDialog-paperFullWidth.css-rnmm7m-MuiPaper-root-MuiDialog-paper {
  height: 50%;
} */

.chartAreaWrapper {
  width: 125em !important;
  overflow-y: hidden;
  overflow-x: scroll;
  /* background: #c9b2b2; */
}
.package-section {
  font: inherit;
  border: 1px solid var(--color-primary);
  border-radius: 5px;
  box-sizing: content-box;
  background: white;
  height: 1.4375em;
  display: block;
  min-width: 0;
  padding-top: 1px;
  padding: 8.5px 14px;
  text-align: center;
}
