.card-div {
  box-shadow: 0px 4px 4px rgb(54 53 76 / 15%);
  border-radius: 13px;
  background-color: var(--color-white);
}

.num-data {
  font-family: var(--family-label);
  font-style: var(--family-style);
  font-weight: 700;
  font-size: var(--fs-subtitle);
  color: var(--color-secondary-dark) !important;
}

.text-data {
  font-family: var(--family-label);
  font-style: var(--family-style);
  font-weight: 500;
  font-size: var(--fs-mid);

  color: var(--color-miscellaneous-2);
}

.div-data {
  /* border-bottom: 1px dashed var(--color-miscellaneous-3); */
  border-bottom: 1px dashed #e7e7f0;
}

.img-star {
  height: 20px;
}

.result-div {
  background-color: var(--color-ternary-light);
  padding-right: 0.8rem;
  border-radius: 20px;
}

.result-div li {
  color: var(--color-ternary);
  font-family: var(--family-label);
}

.origin-num {
  font-family: var(--family-label);
  font-style: var(--family-style);
  font-weight: 600;
  font-size: var(--fs-new);
  color: var(--color-secondary-dark);
}

.origin-date {
  font-family: var(--family-label);
  font-style: var(--family-style);
  /* color: var(--color-miscellaneous-2); */
  font-size: var(--fs-small);
  color: var(--color-secondary-dark);
}

.text-origin {
  font-family: var(--family-label);
  font-style: var(--family-style);
  font-weight: 300;
  font-size: var(--fs-small);

  color: var(--color-secondary-font);
}

.accordian-item {
  box-shadow: none !important;
}

.icon-expand-div {
  min-height: 0 !important;
}

.icon-expand {
  color: var(--color-orange);
}

.Mui-expanded {
  margin: 0 !important;
}

.MuiAccordionSummary-expandIconWrapper {
  border-radius: 50%;
  border: 1px solid var(--color-orange);
}

.typo-text {
  color: var(--color-orange);
  font-family: var(--family-label);
  font-style: var(--family-style);
  font-weight: 500;
  font-size: var(--fs-mid);
  background-color: transparent;
}

.details-div {
  border-bottom: 1px solid var(--color-miscellaneous-1);
  cursor: pointer;
}

.item-detail {
  font-family: var(--family-label);
  font-style: var(--family-style);
  font-weight: 600;
  font-size: var(--fs-mid);
  color: var(--color-secondary-dark);
}

.img-transport {
  height: 3rem;
  width: 3rem;
  margin-right: 50px;
}
.word-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 7.8rem;
}
.border-card-light {
  border: 4px solid #0a2d44;
}
@media only screen and (max-width: 576px) {
  .img-transport {
    height: 2rem;
    width: 2rem;
  }
  .origin-date{
    font-size: 9.5px;
  }
  .origin-num{
    font-size: 9.5px;
  }
}
.flash {
  animation: flash 500ms ease infinite alternate;
}
@keyframes flash {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
