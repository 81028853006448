.new-slot {
  position: absolute;
  left: 90.64%;
  right: 0%;
  top: 82.32%;
  bottom: 0%;
}
.img-arrow-user {
  transition: transform 2s ease;
}
.active-img-arrow-user {
  transform: rotate(45deg);
}
.menu-data {
  font-style: normal;
  font-weight: 500;
  font-size: var(--fs-subhead);
  line-height: 20px;
  display: flex;
  align-items: center;
  color: var(--color-secondary-dark);
}
.createbtn-section {
  background: var(--color-white);
  box-shadow: 0px 2px 9px rgba(54, 53, 76, 0.15);
  border-radius: 12px 12px 0px 0px;
  padding: 0.8rem;
  width: 100%;
}
/* .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: var(--color-secondary-dark) !important;
} */
.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  color: var(--color-secondary-dark) !important;
}

.details-section
  div.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 10px !important;
}
.card-rms {
  color: var(--color-secondary-dark);
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px !important;
}
.css-wb57ya-MuiFormControl-root-MuiTextField-root {
  margin-bottom: 1rem !important;
}
.style-top {
  margin: -6px 5px 0px 0px;
}
.table-data div.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input {
  padding: 0 14px;
}
.table-data div.MuiInputBase-root.MuiOutlinedInput-root {
  width: 70%;
}
.tablecell {
  padding: 0rem !important;
}
.tablecell1 {
  padding: 0rem !important;
  /* width: 50px; */
}
.table-data {
  padding: 16px 5px !important;
  display: flex;
  align-items: center;
}
.table-data
  div.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-row-data .table-data {
  border-right: 1px solid var(--color-miscellaneous-3) !important;
}
.table-row-data .table-data:nth-child(10) {
  border-right: 0px !important;
}
.rms-head {
  font-family: var(--family-label);
  font-style: var(--family-style);
  font-weight: 700;
  font-size: var(--fs-label);
  line-height: 33px;
  color: var(--color-secondary-dark);
}

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-8.css-uiuett-MuiGrid-root {
  padding: 0 !important;
}

.dash-rms .css-kwdskd-MuiDivider-root {
  margin-left: -8px !important;
}
.input-date {
  width: 100%;
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid var(--color-primary-light);
}
.font-rms {
  font-family: var(--family-label);
  font-style: var(--family-style);
  font-weight: 600;
  font-size: var(--fs-subhead);
  color: var(--color-secondary-dark);
}
.font-rmsPara {
  font-family: var(--family-label);
  font-style: var(--family-style);
  font-weight: 600;
  font-size: var(--fs-lg);
  color: var(--color-secondary-dark);
}
.head-textrms {
  font-family: var(--family-label);
  font-style: var(--family-style);
  font-weight: 500;
  font-size: var(--fs-subtitle);
  color: var(--color-miscellaneous-2);
  /* text-align: center; */
}
.font-weight {
  font-weight: 500;
}
.fontsize-rms {
  font-size: var(--fs-subtitle);
}
.head-text-para {
  font-family: var(--family-label);
  font-style: var(--family-style);
  font-weight: 700;
  font-size: var(--fs-lg);
  color: var(--color-secondary-dark);
}

.head-text-span {
  font-family: var(--family-label);
  font-style: var(--family-style);
  font-weight: 500;
  font-size: var(--fs-small);
  color: var(--color-miscellaneous-2);
}

.btn-rms-select {
  font-family: var(--family-label);
  font-style: var(--family-style);
  /* font-weight: 600 !important; */
  font-size: var(--fs-small) !important;
  border-radius: 62px !important;
  text-transform: capitalize !important;
}
.indigo-rms {
  font-family: var(--family-label);
  font-style: var(--family-style);
  font-weight: 600;
  font-size: var(--fs-title);
  color: var(--color-primary);
}
.airlines-rms {
  font-family: var(--family-label);
  font-style: var(--family-style);
  font-weight: 500;
  font-size: var(--fs-small);
  color: var(--color-miscellaneous-2);
}
.side-rms .css-14nw89y-MuiDivider-root {
  margin-left: -8px;
  width: 98%;
}
.grid-rms .css-14nw89y-MuiDivider-root {
  margin-left: 2px;
  width: 98%;
}
.text-side {
  font-size: var(--fs-lg);
}
.text-grid {
  font-size: var(--fs-mid);
}
.box-rms {
  background: #d9f6e7;
  border-radius: 0px 0px 13px 13px;
  color: var(--color-ternary);
  position: absolute;
  left: 10%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  width: 10%;
}
/* ------DocumentRms */

.btn-doc {
  background-color: var(--color-miscellaneous-5);
  border-radius: 35px;
  font-family: var(--family-label);
  font-style: var(--family-style);
  font-weight: 500;
  font-size: var(--fs-mid);
  color: var(--color-secondary-light);
  border: none;
}

.documentation {
  border-spacing: 0 1rem;
  border-collapse: separate;
  background-color: var(--color-miscellaneous-6);
}

.table th {
  border: none;
}

.documentation-row {
  border-bottom: 1px solid var(--color-primary-light);
}

.documentation-info td {
  padding: 0.75rem;
}

.pdf-icon {
  height: 2.5rem !important;
  width: 3rem !important;
}

.index-card {
  height: 67vh;
  overflow-y: auto;
}

div.layout-details-div.mx-3
  div.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4
  div.MuiAccordionSummary-expandIconWrapper.Mui-expanded.css-yw020d-MuiAccordionSummary-expandIconWrapper {
  display: none;
}
.documentation {
  border-spacing: 0 1rem;
  border-collapse: separate;
  background-color: var(--color-miscellaneous-6);
}
.selected-head {
  font-family: var(--family-label) !important;
  font-style: var(--family-style) !important;
  font-weight: 700;
  font-size: var(--fs-subhead);
  line-height: 33px;

  color: var(--color-secondary-dark);
}
.doc-indigo {
  border: 2px solid var(--color-primary);
  font-size: var(--fs-small);
}
.doc-indigo-right {
  color: var(--color-secondary);
  font-size: var(--fs-small);
}
.span-rate {
  font-family: var(--family-label);
  font-style: var(--family-style);
  font-size: var(--fs-small) !important;
  font-weight: 500;
  color: var(--color-secondary-light);
}
.total-rms {
  font-family: var(--family-label);
  font-style: var(--family-style);
  font-weight: 700;
  font-size: var(--fs-head);
  color: var(--color-black);
}
.div-shipment {
  font-family: var(--family-label);
  font-style: var(--family-style);
  font-weight: 600;
  font-size: var(--fs-mid);
  color: var(--color-white);
}
.btn-shipment {
  background: var(--color-secondary-dark) !important;
  box-shadow: 0px -6px 4px rgba(0, 0, 0, 0.16) !important;
  border-radius: 24px !important;
  position: relative;
  z-index: 1;
  padding: 0.5rem;
  width: 100%;
}
.details-section .css-qm9mjt-MuiAutocomplete-root .MuiOutlinedInput-root {
  height: 45px !important;
}
.details-section .css-16gcrfd-MuiFormLabel-root-MuiInputLabel-root {
  top: -3px;
}
.details-section .MuiAutocomplete-input {
  padding: 3.5px 0px 0px 6px !important;
}
.details-section .css-qm9mjt-MuiAutocomplete-root {
  margin-bottom: -19px;
}
.custom-textfield {
  width: 100%;
  align-items: center;
  /* Add any other styling as needed */
}
.custom-textfield .MuiInputBase-root {
  height: 24px; /* Adjust the height as per your requirement */
}
.error1 {
  color: var(--color-miscellaneous-7);
  position: relative;
  top: -2px;
}
.error {
  color: var(--color-miscellaneous-7);
}
.datepicker .css-14p6mks-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: var(--color-mile);
}
.horizontal-datepicker {
  display: flex;
  justify-content: center;
  height: 65px;
}
.select-monthyear {
  width: 100%;
  font-weight: 700;
  font-size: var(--fs-head);
  border: none;
  text-align: center;
  color: var(--color-secondary-dark);
}

.select-monthyear:focus {
  border: 0px solid;
}
.dates {
  display: flex;
  flex-wrap: nowrap;
  max-width: 100%;
  overflow-x: auto;
}

.date {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin: 5px;
  border-radius: 50%;
  cursor: pointer;
  background-color: var(--color-miscellaneous-3);
}

.date.selected {
  background-color: var(--color-primary-dark);
  color: var(--color-white);
}
.date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin: 5px;
  border-radius: 50%;
  cursor: pointer;
  background-color: var(--color-miscellaneous-3);
}

.day-of-week {
  font-size: 10px;
}

.day {
  font-size: 16px;
}

.date.blank {
  background-color: transparent;
  pointer-events: none;
}
.css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
  box-shadow: rgba(214, 212, 212, 0.35) 0px 2px 5px !important;
}

.selected-date {
  background-color: var(
    --color-primary-dark
  ); /* Set the desired background color for the selected date */
  color: var(
    --color-white
  ); /* Set the desired text color for the selected date */
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  margin-left: 30%;
  /* Adjust the height as needed */
}
.buttons-container {
  display: flex;
  align-items: center;
  margin-left: 0px;
}

.buttons-container > button {
  height: 2.5rem;
  width: max-content;
  margin-left: 5px;
  font-size: var(--fs-mid);
  background-color: var(--color-secondary-dark);
  color: var(--color-white);
  border-radius: 16px;
  font-weight: 500;
}
.buttons-container :hover {
  background-color: var(--color-secondary-button) !important;
  color: var(--color-black);
}
.headingRmsCss {
  color: var(--color-primary-font);
  font-size: var(--fs-head) !important;
  font-weight: 500 !important;
  font-family: var(--family-label);
  text-align: center;
}
.active-tab {
  color: var(--color-secondary-dark) !important;
  font-size: 18px !important;
  font-weight: 700 !important;
}
.non-active {
  font-size: 18px !important;
}
.css-3ji0q7-MuiTabs-indicator {
  position: absolute;
  height: 2px;
  bottom: 0;
  width: 100%;
  -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: var(--color-secondary-dark) !important;
}
/* Add tooltip*/
.custom-tooltip {
  font-size: 76px; /* Adjust the font size as needed */
}
.chageicon180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.chageicon180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.center-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh; /* Adjust this value based on your needs */
  font-size: large;
}
.headingStyle {
  font-size: "18px";
}
.paddingstyle {
  padding-left: 60px !important;
}
.table-container {
  overflow-x: auto;
  max-width: 100%;
  width: 100%;
}
.table-bordered th:first-child {
  width: 0px !important;
}
.check-autocomplete {
  width: 100% !important;
}
.check-autocomplete > div {
  margin-bottom: 0rem !important;
}
/* Media query for mobile devices */
@media screen and (max-width: 470px) {
  .tableresponsive {
    overflow-x: auto !important;
    display: block !important;
  }
  .paddingstyle {
    padding-left: 60px !important;
  }
}
@media screen and (min-width: 471px) {
  .paddingstyle {
    padding-left: 60px !important; /* Maintain original padding for larger screens */
  }
}
/* CSS for screens between 899px and 1199px */
/* @media screen and (min-width: 899px) and (max-width: 1199px) {
  .MuiGrid-item {
    min-width: 100%;
  }
} */
